import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import HomeContent from "./pages/Home";
import NotFound from "./pages/NotFound";

// Middleware component to handle redirection logic
const Home = () => {
  const navigate = useNavigate();
  
  const getUniqueId = () => {
    // Get the raw URL before any React Router processing
    const rawUrl = window.location.toString();
    console.log('[Redirect] Raw URL:', rawUrl);

    try {
      // Method 1: Using URL constructor with toString()
      const urlObj = new URL(rawUrl);
      const qParam1 = urlObj.searchParams.get('q');
      
      // Method 2: Using URLSearchParams with location.search
      const searchParams = new URLSearchParams(window.location.search);
      const qParam2 = searchParams.get('q');

      // Method 3: Manual query string parsing
      const queryString = window.location.href.split('?')[1];
      const qParam3 = queryString ? new URLSearchParams(queryString).get('q') : null;
      
      console.log('[Redirect] URL parsing attempts:', {
        method1: qParam1,
        method2: qParam2,
        method3: qParam3,
        rawUrl,
        search: window.location.search,
        queryString
      });

      // Return the first successful result
      return qParam1 || qParam2 || qParam3 || null;
    } catch (error) {
      console.error('[Redirect] URL parsing error:', error);
      return null;
    }
  };

  // Get uniqueId immediately
  const uniqueId = getUniqueId();

  useEffect(() => {
    const handleRedirect = async () => {
      console.log('[Redirect] Starting redirect with uniqueId:', uniqueId);
      
      if (uniqueId) {
        try {
          const apiUrl = `${process.env.REACT_APP_DEV_API}/v1/job/unique-id/${uniqueId}`;
          console.log('[Redirect] Fetching from:', apiUrl);
          
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (response.ok && data.data?.url) {
            console.log('[Redirect] Redirecting to:', data.data.url);
            window.location.href = data.data.url;
            return;
          } else {
            console.error('[Redirect] API error:', data);
            toast.error(data.message || "Job not found");
          }
        } catch (error) {
          console.error('[Redirect] Fetch error:', error);
          toast.error("An unexpected error occurred");
        }
      } else {
        console.log('[Redirect] No uniqueId found in URL');
      }
    };

    handleRedirect();
  }, [uniqueId]);

  return <HomeContent />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
