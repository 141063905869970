import React, { useState, useEffect } from "react";
import Modal from "./Modal";

const createCandidateCountGenerator = (baseTimestamp, offset, maxDailyIncrease = 1000) => {
  const now = Math.floor(Date.now() / 1000); // Current Unix time in seconds
  const elapsedDays = Math.floor((now - baseTimestamp) / 86400); // Convert seconds to days

  // Calculate the base count dynamically with a daily scaling factor
  const baseCount = elapsedDays * maxDailyIncrease + offset;
  let currentCount = baseCount;

  return function getNextCount() {
    // Random increment to simulate natural growth
    const increment = Math.floor(Math.random() * 3) + 1; // Random increment: 1, 2, or 3
    currentCount += increment; // Always increase the count
    return currentCount;
  };
};

const formatNumberIndianSystem = (num) => {
  const numStr = num.toString();
  let result = '';
  
  // Handle last 3 digits
  const lastThree = numStr.length > 3 ? numStr.slice(-3) : numStr;
  result = lastThree;
  
  // Handle the rest of the digits
  for (let i = numStr.length - 3; i > 0; i -= 2) {
    result = numStr.slice(Math.max(i - 2, 0), i) + ',' + result;
  }
  
  return result;
};

const Header2 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateCount, setCandidateCount] = useState(0);

  // Add the candidate count generator
  useEffect(() => {
    const baseTimestamp = 1700000000;
    const offset = 316432;
    const getNextCount = createCandidateCountGenerator(baseTimestamp, offset);
    
    // Update count initially and every 2 seconds
    const updateCount = () => {
      const count = getNextCount();
      setCandidateCount(count);
    };
    
    updateCount();
    const interval = setInterval(updateCount, 2000);
    
    return () => clearInterval(interval);
  }, []);

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  return (
    <section className="relative overflow-hidden bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-28">
      {/* Floating Geometric Shapes */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <div className="shape shape-1 rotate-animation"></div>
        <div className="shape shape-2 pulse-animation"></div>
        <div className="shape shape-3 rotate-reverse-animation"></div>
        <div className="shape shape-4 pulse-animation"></div>
        <div className="shape shape-5 rotate-animation"></div>
        <div className="particle particle-1 fade-in-animation"></div>
        <div className="particle particle-2 pulse-animation"></div>
        <div className="particle particle-3 fade-in-animation"></div>
        <div className="particle particle-4 pulse-animation"></div>
        <div className="particle particle-5 fade-in-animation"></div>
      </div>

      {/* Content */}
      <div className="relative container mx-auto px-6 text-center z-10">
        <h1 className="hero-text text-6xl font-extrabold leading-tight relative">
          <span className="block overflow-hidden">
            <span className="text-reveal block">Smarter Hiring Starts Here</span>
          </span>
        </h1>
        <p className="mt-6 text-xl font-light relative">
          <span className="block overflow-hidden">
            <span className="text-reveal block">
              AI-powered insights. Better candidates. Faster decisions.
            </span>
            <span 
              className="text-reveal block" 
              style={{
                padding: '1rem 0',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 600
              }}
            >
              Analyzed <b style={{ fontSize: '2.4rem', padding: '0 0.5rem' }}>
                {formatNumberIndianSystem(candidateCount)}
              </b> Candidates
            </span>
          </span>
        </p>

        {/* CTA Button */}
        <a
          id="onboarding-btn"
          className="cta-btn inline-block mt-8 bg-white text-indigo-600 px-8 py-4 rounded-full text-xl font-semibold hover:bg-gray-100 transition-all duration-300 ease-in-out cursor-pointer"
          onClick={toggleModal}
        >
          Try TalentSwap
        </a>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </section>
  );
};

export default Header2;
